import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
// import { MsalGuardConfiguration } from './msal/msal.guard.config';
// import { MsalInterceptorConfiguration } from './msal/msal.interceptor.config';
import { inTeams } from './helpers/teams.helper';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { getEnv } from '@project-hub/angular-common';


export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: getEnv('clientId'),
      authority: `https://login.microsoftonline.com/${ getEnv('tenantId') }`,
      redirectUri: '/auth',
      postLogoutRedirectUri: '/logout',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  if (inTeams()) {
    protectedResourceMap.set(
      `${ location.protocol }//${ location.host }/api`,
      ['access_as_user'],
    );
  } else {
    protectedResourceMap.set(
      `${ location.protocol }//${ location.host }/api`,
      [`${ getEnv('authScope') }/access_as_user`],
    );
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
