declare global {
  interface Window {
    env: {
      isOnward: string;
      tenantId: string;
      clientId: string;
      authScope: string;
    };
  }
}

export function getEnv(key: keyof Window['env']): string {
  return window.env[key];
}
