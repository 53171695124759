import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';


@Component({
  selector: 'pa-teams-layout',
  templateUrl: './teams-layout.component.html',
  styleUrl: './teams-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
  ],
})
export class TeamsLayoutComponent {
}
